<template>
  <v-container class="service-info-container" fluid>
    <!-- 설명 텍스트 섹션 -->
    <v-card class="mb-5" outlined>
      <v-card-title class="headline font-weight-bold">에어라커 서비스 안내</v-card-title>
      <v-card-text>
	<v-img max-width="100%" :src="require('@/assets/service_info.png')" class="location-img"></v-img>
      </v-card-text>
    </v-card>

    <v-card class="mx-auto mb-5" outlined>
        <v-col>
          <v-card-title class="font-weight-bold" color="blue lighten-1" style="border-right: 1px solid #eee">서비스
            장점</v-card-title>
          <v-card-text style="border-right: 1px solid #eee">
	    <v-img max-width="100%" :src="require('@/assets/service_intro.png')" class="location-img"></v-img>
          </v-card-text>
        </v-col>
    </v-card>

    <v-card class="mx-auto mb-5" outlined>
    <v-col>
        <v-card-title class="font-weight-bold" color="blue lighten-1" style="border-right: 1px solid #eee">
            이용 후기
        </v-card-title>
        <v-card-text style="border-right: 1px solid #eee">
            <ul>
                <li><a href="https://www.meta-chain.co.kr/%ec%9d%b8%ec%b2%9c%ea%b3%b5%ed%95%ad-%ec%a7%90%eb%b3%b4%ea%b4%80-%ec%8a%a4%eb%a7%88%ed%8a%b8%ed%95%98%ea%b2%8c-%eb%82%b4%eb%8f%88%eb%82%b4%ec%82%b0-%ed%9b%84%ea%b8%b0-ver-2024/" target="_blank">
                    인천공항 짐보관 스마트하게! 내돈내산 후기 (2024 Ver.)
                </a></li>
                <li><a href="https://developrovemyself.tistory.com/11" target="_blank">
                    겨울 여행 필수품! 짐 보관 서비스
                </a></li>
                <li><a href="https://money-digger.tistory.com/9" target="_blank">
                    겨울 여행의 필수 파트거, Airlocker: 짐 걱정 없이 떠나는 가벼운 여행
                </a></li>
                <li><a href="https://blog.naver.com/bg90502/223617154271" target="_blank">
                    여행을 더 가볍게! 겨울 짐 보관과 필수 아이템 대여를 한 번에 해결하는 Airlocker
                </a></li>
            </ul>
        </v-card-text>
    </v-col>
</v-card>


    <!-- 위치 안내 섹션 -->
    <v-card class="mb-5" outlined id="location-section">
      <v-card-title class="headline font-weight-bold">위치 안내</v-card-title>
      <v-card-subtitle class="font-weight-bold" style="font-size: 1.25rem; color: #1976D2; background-color: #E3F2FD; padding: 10px; border-radius: 5px;">
        1층에서 오시는 방법
      </v-card-subtitle>
      <v-card-text>
        <!-- 이미지들을 순차적으로 배치 -->
        <v-img max-width="100%" :src="floor_one_0" class="location-img"></v-img>
        <v-img max-width="100%" :src="floor_one_1" class="location-img"></v-img>
        <v-img max-width="100%" :src="floor_one_2" class="location-img"></v-img>
        <v-img max-width="100%" :src="floor_mannam" class="location-img"></v-img>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitlei class="font-weight-bold" style="font-size: 1.25rem; color: #1976D2; background-color: #E3F2FD; padding: 10px; border-radius: 5px;">
        3층에서 오시는 방법
      </v-card-subtitle>
      <v-card-text>
        <!-- 이미지들을 순차적으로 배치 -->
        <v-img max-width="100%" :src="floor_three_0" class="location-img"></v-img>
        <v-img max-width="100%" :src="floor_three_1" class="location-img"></v-img>
        <v-img max-width="100%" :src="floor_three_2" class="location-img"></v-img>
        <v-img max-width="100%" :src="floor_mannam" class="location-img"></v-img>
      </v-card-text>
    </v-card>


    <!-- 예약 방법 / 확인 방법 안내 섹션 -->
    <v-card class="mb-5" outlined>
      <v-card-title>예약 방법</v-card-title>
      <v-card-text>
        <v-img max-width="100%" :src="reserv_guide" class="location-img"></v-img>
      </v-card-text>
    </v-card>
    <v-card class="mb-5" outlined>
      <v-card-title>예약확인 방법</v-card-title>
      <v-card-text>
        <v-img max-width="100%" :src="reserv_check_guide" class="location-img"></v-img>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'ServiceInfoPage',
  data() {
    return {
      floor_one_0: require('@/assets/1floor_0.png'),
      floor_one_1: require('@/assets/1floor_1.png'),
      floor_one_2: require('@/assets/1floor_2.png'),
      floor_mannam: require('@/assets/2floor_mannam.png'),
      floor_three_0: require('@/assets/3floor_0.png'),
      floor_three_1: require('@/assets/3floor_1.png'),
      floor_three_2: require('@/assets/3floor_2.png'),
      reserv_guide: require('@/assets/reserv_guide.png'),
      reserv_check_guide: require('@/assets/reserv_check_guide.png'),
    };
  },
  methods: {
    goToPage(pageName) {
      this.$router.push({ name: pageName });
    },
    scrollToLocation() {
      const locationSection = document.getElementById('location-section');
      if (locationSection) {
        locationSection.scrollIntoView({ behavior: 'smooth' });
      }
    },
  }
};
</script>

<style scoped>
.service-info-container {
  max-width: 1200px;
  margin: 0 auto;
}

.benefits-list {
  padding-left: 20px;
  list-style-type: disc;
}

.benefits-list li {
  margin-bottom: 10px;
  font-size: 16px;
}

.headline {
  font-size: 24px;
}

.font-weight-bold {
  font-weight: bold;
}
</style>
